<template>
    <div
        class="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32"
    >
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div
                class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2"
            >
                <div class="max-w-xl lg:max-w-lg">
                    <h2
                        class="text-3xl font-bold tracking-tight text-white sm:text-4xl"
                    >
                        Join Dubai's Remote Work Revolution.
                    </h2>
                    <p class="mt-4 text-lg leading-8 text-gray-300">
                        Stay ahead in your career with Dubai's first platform
                        dedicated to remote and hybrid job opportunities.
                        Subscribe for weekly insights and job alerts directly to
                        your inbox.
                    </p>
                    <form
                        v-on:submit.prevent="emailSubmit"
                        class="mt-6 flex max-w-md gap-x-4"
                    >
                        <label for="email-address" class="sr-only"
                            >Email address</label
                        >
                        <input
                            v-model="emailForm.email"
                            id="email-address"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required=""
                            class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                            placeholder="Enter your email"
                        />
                        <button
                            :disabled="emailForm.processing"
                            type="submit"
                            class="flex-none rounded-md bg-cyan-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500"
                        >
                            Subscribe
                        </button>
                    </form>
                </div>
                <dl
                    class="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2"
                >
                    <div class="flex flex-col items-start">
                        <div
                            class="rounded-md bg-white/5 p-2 ring-1 ring-white/10"
                        >
                            <BellAlertIcon
                                class="h-6 w-6 text-white"
                                aria-hidden="true"
                            />
                        </div>
                        <dt class="mt-4 font-semibold text-white">
                            Weekly Job Alerts
                        </dt>
                        <dd class="mt-2 leading-7 text-gray-400">
                            Subscribe to receive curated lists of the best
                            remote and hybrid job opportunities in Dubai,
                            tailored to your skills and interests.
                        </dd>
                    </div>
                    <div class="flex flex-col items-start">
                        <div
                            class="rounded-md bg-white/5 p-2 ring-1 ring-white/10"
                        >
                            <NewspaperIcon
                                class="h-6 w-6 text-white"
                                aria-hidden="true"
                            />
                        </div>
                        <dt class="mt-4 font-semibold text-white">
                            Weekly Blog Newsletter
                        </dt>
                        <dd class="mt-2 leading-7 text-gray-400">
                            Get the latest insights, trends, and advice on
                            remote work every week to help you thrive in the
                            evolving work environment.
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        <div
            class="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
            aria-hidden="true"
        >
            <div
                class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-cyan-300 to-cyan-800 opacity-30"
                style="
                    clip-path: polygon(
                        74.1% 44.1%,
                        100% 61.6%,
                        97.5% 26.9%,
                        85.5% 0.1%,
                        80.7% 2%,
                        72.5% 32.5%,
                        60.2% 62.4%,
                        52.4% 68.1%,
                        47.5% 58.3%,
                        45.2% 34.5%,
                        27.5% 76.7%,
                        0.1% 64.9%,
                        17.9% 100%,
                        27.6% 76.8%,
                        76.1% 97.7%,
                        74.1% 44.1%
                    );
                "
            />
        </div>
    </div>
</template>

<script setup>
import { BellAlertIcon, NewspaperIcon } from "@heroicons/vue/24/outline";

import { useEmailForm } from "@/utils/subscribe.js";
import Button from "@/Components/Guest/Button.vue";

const { emailForm, emailSubmit } = useEmailForm();
</script>
